@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');

.nav{
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* padding: 10px; */
    background: var(--mocha);
}
.nav-logo{
    height: 60px;
    width: 60px;
}

.nav-pages{
    /* width: 230px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-right: 20px; */
}

.text-link {
    width: 300px;
    height: 80px;
    padding: 0px 50px 0px 50px;
    display: flex;
    align-items: center;
    background: var(--green);
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 1.5rem;
    color: var(--black);
    text-decoration: none;
}

.text-link:hover {
    color: var(--white);
    background: var(--black);
}

#file{
    color: var(--black);
    text-decoration: none;
    font-family: 'Roboto';
    border-radius: 20px;
    border: 0.5px solid #000;
    padding: 10px 20px 10px 20px;
    position: absolute;
    left: 780px;
    top: 20px;
}
#file:hover{
    color: var(--white);
    background: var(--black);
}

@media only screen and (max-width:1024px) {
    .nav{
        display: none;
    }
}