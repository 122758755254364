.home-container{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background: linear-gradient(var(--mocha), var(--cafe))
}
.box-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.box-text{
    width: 850px;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;
}

.home-tittle{
    /* text-align: left; */
    font-family: 'Roboto';
    font-weight: 800;
    color: var(--chocolate);
    font-size: 130px;
    padding: 30px 0px 180px 0px;
    text-transform: uppercase;
    text-align: center;
}

.box-below{
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px 30px 20px 30px;
}

.box-above{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-size: 15px;
    padding-top: 100px;
    text-transform: uppercase;
}

.box-above p:nth-child(1){
    font-size: 20px;
}

.box-above p:nth-child(2){
    color: var(--black);
}

.box-design{
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--black);
    font-size: 15px;
    text-transform: uppercase;
    position: absolute;
    top: 400px;
}

.box-design:nth-child(1){
    left: 100px;
}
.box-design:nth-child(2){
    left: 300px;
}
.box-design:nth-child(3){
    right: 300px;
}
.box-design:nth-child(4){
    right: 100px;
}

.home-container img {
    width: 250px;
    position: absolute;
    bottom: -12px;
    right: 50px;
}
@media only screen and (max-width:1024px){
    .home-container {
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }

    .home-tittle{
        text-align: left;
        font-family: 'Roboto';
        font-weight: 800;
        color: var(--black);
        font-size: 80px;
        margin-top: 0px;
        text-transform: uppercase;
        text-align: center;
        line-height: 1;
    }

    .box-design, .box-below{
        display: none;
    }
}