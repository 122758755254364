
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* cursor: none; */
  
  --border-radius: 50px;
  --black: #000;
  --white: #ede8e4;
  --purple: #d19ef6;
  --green: #deff00;
  --gutter: 3.125vw 6.25vw;
  --margin: 10px;
  --title-padding: 50px;
  --mocha: #B79487;
  --cafe: #836052;
  --bg-secondary: #d7cfc4;
  --chocolate: #5d443a;

}

