@font-face {
    font-family: "DanRoxboroughCF-RegularItalic";   /*Can be any text*/
    src: local("RoxboroughCF-RegularItalic"),
      url("./../../fonts/RoxboroughCF-RegularItalic.otf") format("opentype");
  }

.contact-section{
    width: 100%;
    height: 100vh;
    background: var(--white);
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    box-sizing: border-box;
    overflow: hidden;
}

.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.contact-container img{
    width: 800px;
}
.header-contact{
    display: flex; 
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 50px;
}

.contact-container a {
    font-family: 'Roboto';
    text-decoration: none;
    background-size: 0% 3px;
    transition: background-size .3s ease;
}

.contact-container a:hover{
    color: var(--green);
    background: linear-gradient(to left, var(--green) 100%);
    background-position: 0 100%;
    background-size: 100% 3px;
    background-repeat: repeat-x;
}

.div-column {
    display: flex;
    flex-direction: column;
    
}
.div-column p{
    font-family: 'Roboto';
    font-weight: 400;
    color: var(--black);
    margin-top: 0px;
}
.div-column a {
    font-family: 'Roboto';
    color: var(--black);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
}

.contact-hr{
    height: 1px;
    width: 100%;
    border: 0;
    background-color: rgb(104, 104, 104);
}

.div-links{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px;
}

.div-links a{
    margin-right: 30px;
}

.div-links p{
    color:var(--black); 
    font-family:'Roboto'; 
    font-size: 16px;
     margin: 0px 30px 0px 0px;
}

.div-center{
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin: 50px;
}
@media only screen and (max-width:1024px){
 
    .contact-section{
        height: 100vh;
        display: flex;
        align-items: stretch;
        justify-content: space-around;
        box-sizing: border-box;
        overflow: hidden;
    }
    .div-container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
    }

    .header-contact{
        display: flex; 
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
   
    .div-column p{
        font-weight: 800;
        font-size: 18px;
    }

    .div-column a{
        margin-top: 10px;
        font-size: 18px;
        text-transform: uppercase;
    }

    .div-center, .contact-hr, #bs{
        display: none;
    }

    .div-links{
        height: 50%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: flex-start;
    }

}