.container{
    display:flex; 
    height: 200px; 
    align-items: center;
    justify-content: center;
}
.bg-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--cafe);
}

.txt{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 3.75rem;
    color: var(--white);
    /* padding-left: 2rem; */
}

.section-container{
    position: relative;  
    height: 80vh;
    overflow-x: hidden;
    background: var(--chocolate);
    width: 100vw;
}

.card-container{
    position: sticky;
    top: 0;
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
}

.axis-x{
    display: flex;
    gap: 16px;
}

.card{
    position: relative;
    height: 450px;
    width: 450px;
    overflow: hidden;
    background: var(--bg-secondary);
    /* transition: transform .4s; */
}
/* .card:hover{
    transform: scale(1.2);
    margin: 0 4rem 0 4rem;
} */

.card-info{
    position: absolute;
    inset: 0;
    z-index: 0;
    transition-property: transform;
    transition-timing-function: ease, cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 800ms;
}
.card-info:hover{
    transform: scale(1.5);
}

.title{
    position: absolute;
    inset: 0;
    z-index: 10;
    display: grid;
    place-content: center;
}

.txt-title{
    padding: 32px;
    font-size: 3.75rem;
    line-height: 1;
    text-transform: uppercase;
    color: var(--cafe);
    backdrop-filter: blur(100px);
}

