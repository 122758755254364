.skill-container{ 
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: var(--bg-secondary);
  padding: 200px 0px 200px 0px;
  position: relative;
}

.projects-list{
  width: 100%;
  padding-top: 100px;
}

.skill-h1{
  font-size: 150px;
  color: var(--white);
  font-weight: 400;
}

.skill-container p{
  font-family: 'Roboto';
  color: var(--white);
  text-transform: uppercase;
  font-size: 16px;
  padding-top: 16px;
}

.font-sec{
  font-family: "DanRoxboroughCF-RegularItalic";
}

#gopher{
  width: 180px;
  position: absolute;
  left: 470px;
  top: 50px;
}

#angular{
  width: 180px;
  position: absolute;
  right: 250px;
  top: 350px;
}

#react{
  width: 180px;
  position: absolute;
  left: 100px;
  top: 400px;
}

@media only screen and (max-width:1024px) {  
  .skill-container{
    width: 100%;
  }

  .skill-h1{
    font-size: 80px;
  }

  img {
    display: none;
  }
}

